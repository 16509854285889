<script lang="ts" setup>
import { useRouter } from "vue-router"

const router = useRouter()
withDefaults(
  defineProps<{
    callback?: () => void
    desriptionText?: string
    buttonText?: string
    headerText?: string
  }>(),
  {
    callback: undefined,
    desriptionText: "Wir konnten die Seite oder einen Datensatz nicht finden.",
    buttonText: "Zurück zur Startseite",
    headerText: "404",
  }
)
</script>

<template>
  <div class="empty-wrapper">
    <div class="empty-content">
      <img src="@/assets/404.svg" style="max-width: 404px" class="mb-5" />
      <h5 v-if="headerText" class="empty-content-header">{{ headerText }}</h5>
      <p v-if="desriptionText" class="empty-content-desc">{{ desriptionText }}</p>
      <ps-button v-if="buttonText" @click="callback?.() || router.push('/')" class="mt-2" active size="small">
        {{ buttonText }}
      </ps-button>
    </div>
  </div>
</template>
