import { getCurrentInstance } from "vue"

export const useRealtime = () => {
  const instance = getCurrentInstance()

  if (!instance) {
    throw new Error("useRealtime must be called within a composition API component")
  }

  const vm = instance.proxy!

  return vm.$pusher
}

export const usePusher = useRealtime
