import { useQuery, useQueryClient } from "@tanstack/vue-query"

import { QueryKeys } from "../query-keys"
import { ApiResponse, isSuccessResponse } from "../services/base-client"
import ServiceProvider from "../services/service-provider"
import { Integration, IntegrationId } from "../types/integration-service"

export const fetchIntegrations = async () => {
  const response = await ServiceProvider.integrations.getIntegrations()

  if (isSuccessResponse(response as ApiResponse<Integration[]>)) {
    // store the integrations in a map for easier access
    const integrationsList = response.data?.integrations as Integration[]

    return integrationsList.reduce((acc, integration) => {
      acc[integration.id] = integration
      return acc
    }, {} as Record<string, Integration>)
  }

  return null
}

export const fetchSingleIntegration = async (integrationId: IntegrationId) => {
  const response = await ServiceProvider.integrations.getIntegration(integrationId)

  if (isSuccessResponse(response as ApiResponse<Integration>)) {
    return response.data as Integration
  }

  return null
}

export const useRefetchSingleIntegration = () => {
  const queryClient = useQueryClient()

  return async (integrationId: IntegrationId) => {
    const integration = await fetchSingleIntegration(integrationId)

    if (integration) {
      // Update the integration within the all integrations query data
      queryClient.setQueryData<Record<string, Integration> | null>(QueryKeys.integrations.all(), oldData => {
        if (!oldData) return null

        return {
          ...oldData,
          [integrationId]: integration,
        }
      })

      return integration
    }

    return null
  }
}

export const useRefetchAllIntegrations = () => {
  const queryClient = useQueryClient()
  return async () => {
    await queryClient.invalidateQueries({ queryKey: QueryKeys.integrations.all() })
  }
}

export const useIntegrationsQuery = () =>
  useQuery({
    queryKey: QueryKeys.integrations.all(),
    queryFn: fetchIntegrations,
    staleTime: 1000 * 60 * 10, // 10 minutes
    refetchOnMount: false,
  })
