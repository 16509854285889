<template>
  <div>
    <slot v-bind="{ shop, fetch, loading, update, updating }" v-if="fetched"></slot>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"

export default defineComponent({
  emits: ["update:shop", "fetch-error"],
  expose: ["fetch", "shop", "loading", "updating", "update", "fetched"],
  props: {
    fields: {
      type: Array as PropType<string[]>,
      required: true,
    },
    init: {
      type: Function,
      required: false,
    },
    omittedKeys: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    autoUpdateDb: {
      type: Boolean,
      default: false,
    },
    autoRefreshDb: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shop: {} as Record<string, unknown>, // ensure initial reactivity
      loading: false,
      updating: false,
    }
  },
  computed: {
    fetched(): boolean {
      return !!Object.keys(this.shop).length
    },
  },
  async mounted() {
    await this.fetch()
  },
  methods: {
    update(newData, callback) {
      const oldState = JSON.parse(JSON.stringify(this.shop))
      Object.assign(this.shop, newData)
      const payload = _.omit(newData, this.omittedKeys)
      this.updating = true
      return this.$api
        .updateShop(payload)
        .then(res => {
          if (callback) callback()
          if (this.autoUpdateDb) this.$db.updateShopFields(payload)
          if (this.autoRefreshDb) this.fetch()
          return res
        })
        .catch(err => {
          console.log("ERROR", err)
          this.shop = oldState
          this.$axios.handleError(err)
        })
        .finally(() => {
          this.updating = false
        })
    },
    async fetch() {
      this.loading = true
      const res = await this.$graphql(
        `{
          shop {
              ${this.fields.join(",")}
          }
        }`,
        {},
        false,
        false,
        true
      ).catch(err => {
        this.$emit("fetch-error", err.response.data.errors)
      })

      if (!res) {
        this.loading = false
        return
      }

      this.shop = res.shop
      if (this.init) {
        this.shop = this.init(this.shop)
      }
      this.loading = false
      this.$emit("update:shop", this.shop)
    },
  },
})
</script>
