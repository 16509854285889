import { useMutation, useQueryClient } from "@tanstack/vue-query"
import { MaybeRef, get } from "@vueuse/core"
import QueryKeys from "../query-keys"
import ServiceProvider from "../services/service-provider"
import { IntegrationOrder } from "../types/integration-order-service"
import { IntegrationId } from "../types/integration-service"
import { QueryApiError } from "./query-api-error"

async function deleteIntegrationOrder(order: IntegrationOrder) {
  const response = await ServiceProvider.orders.deleteOrder(order.id)
  if (response.isSuccessful2xx) {
    return order // should return the original order when deleted?
  } else {
    // deleting fails should error
    throw new QueryApiError(response)
  }
}

export const useIntegrationOrderByEntityDeleteMutation = (integrationId: MaybeRef<IntegrationId>) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ order }: { order: IntegrationOrder }) => deleteIntegrationOrder(order),
    onMutate: ({ order }) => {
      const queryKey = QueryKeys.orders.byIntegrationByEntityId(get(integrationId), get(order).entityId)
      const oldOrder = queryClient.getQueryData(queryKey) as IntegrationOrder | null

      // remove the order from the cache optimistically
      queryClient.setQueryData(queryKey, null)
      return { oldOrder }
    },
    onError: (_, _2, context) => {
      if (context?.oldOrder) {
        const queryKey = QueryKeys.orders.byIntegrationByEntityId(get(integrationId), context.oldOrder.entityId)
        // add the order back to the cache
        queryClient.setQueryData(queryKey, context.oldOrder)
        // still need to invalidate the query to refetch the orders
        queryClient.invalidateQueries({ queryKey })
      }
    },
  })
}
