<template>
  <component :is="tag" v-bind="props" class="inline-flex">
    <span
      :class="['propvision-ring', { 'propvision-ring--loading': loading }]"
      :style="{
        '--ring-size': size + 'px',
        '--inner-ring-size': innerRingSize + 'px',
      }"
    >
      <slot />
    </span>
  </component>
</template>

<script setup lang="ts">
import { defineProps } from "vue"

const props = defineProps<{
  tag: string | object
  props?: Record<string, any>
  loading?: boolean
  size?: number // in px
  ringWidth?: number // in px
}>()

// Set default values
const size = props.size ?? 20
const ringWidth = props.ringWidth ?? 6
const innerRingSize = size - ringWidth ?? 16
</script>

<style scoped>
.propvision-ring {
  display: inline-block;
  width: var(--ring-size);
  height: var(--ring-size);
  border-radius: 50%;
  background: var(--ps-ai-gradient-conic);
  position: relative;
  transition: transform 0.3s ease-in-out;
  -webkit-mask: radial-gradient(circle, transparent calc(var(--inner-ring-size) / 2 - 1px), rgba(0, 0, 0, 0.5) calc(var(--inner-ring-size) / 2), black calc(var(--inner-ring-size) / 2 + 1px));
  mask: radial-gradient(circle, transparent calc(var(--inner-ring-size) / 2 - 1px), rgba(0, 0, 0, 0.5) calc(var(--inner-ring-size) / 2), black calc(var(--inner-ring-size) / 2 + 1px));
  background-clip: border-box;
}

.propvision-ring:hover, .propvision-ring--loading {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
