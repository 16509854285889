<template>
  <nice-popover
    ref="popover"
    placement="bottom"
    :show-arrow="false"
    :trigger="trigger"
    :width="width"
    :hide-after="0"
    transition="none"
    @show="emit('open')"
    :disabled="disabled || undefined"
    :append-to-body="appendToBody"
    v-bind="$attrs"
  >
    <template #reference>
      <slot name="reference" v-bind="{ referenceClass: disabled ? 'disabled' : '' }">
        <button :disabled="disabled || undefined" :class="[buttonClass, disabled ? 'disabled' : '']" @click.prevent>
          <slot name="title">
            <fa-icon name="ellipsis-h" />
          </slot>
        </button>
      </slot>
    </template>
    <main @click="hide">
      <slot />
    </main>
  </nice-popover>
</template>

<script setup lang="ts">
import { ref } from "vue"

const {
  hideOnSelect = true,
  buttonClass = "tableActions-action",
  width = "240",
  trigger = "click",
  appendToBody = true,
  disabled,
} = defineProps<{
  hideOnSelect?: boolean
  buttonClass?: string
  trigger?: "click" | "hover"
  width?: string
  appendToBody?: boolean
  disabled?: boolean
}>()

const emit = defineEmits(["open"])

const popover = ref(null)

const hide = () => {
  if (hideOnSelect) {
    popover.value?.hide()
  }
}

defineExpose({
  hide,
})
</script>

<style scoped>
main {
  max-height: 80vh;
  overflow: auto;
}
</style>
