import { MaybeRef, get } from "@vueuse/core"
import { computed } from "vue"
import { Integration, IntegrationId } from "./types/integration-service"
import { useIntegrations } from "./use-integrations"

// use in combination with useIntegrationAPI
export const useIntegration = (integrationId: MaybeRef<IntegrationId>) => {
  const { data, isPending, refetchSingle, refetchAll } = useIntegrations()

  // HINT: this ensures type safety, but not necessarily the api response. we should improve that.
  const integration = computed(() => (get(data) ?? {})[get(integrationId)] as Integration)

  const isAvailable = computed(() => Boolean(get(integration)))

  const orderSteps = computed(
    () => get(integration)?.fixedConfiguration?.orderSteps ?? { maxVisibleSteps: 0, steps: [] }
  )

  const isEnabled = computed(() => get(integration)?.configuration.enabled ?? false)

  return {
    isEnabled,
    refetchAll,
    refetch: () => refetchSingle(get(integrationId)),
    isAvailable,
    isPending,
    integration,
    orderSteps,
  }
}
