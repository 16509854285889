import { ApiErrorResponse } from "../services/base-client"

export class QueryApiError extends Error {
  response: ApiErrorResponse
  constructor(response: ApiErrorResponse<any>, ...params: any[]) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, QueryApiError)
    }

    this.name = "QueryApiError"
    this.response = response

    const description = response?.data?.description ?? undefined
    const translatedStatusMessage = response?.translatedStatusMessage ?? undefined
    const message = [translatedStatusMessage, description].filter(Boolean).join(" - ")

    this.message = message || this.stack?.toString() || "unknown error"
  }
}
