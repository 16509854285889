import { ImageType } from "@/components/media/media.types"
import { createWebhookUrl } from "@/config/url"
import {
  IntegrationOrderCreateDataPropvisionEnhance,
  IntegrationOrderCreateDataPropvisionStage,
  IntegrationOrderCreateDataPropvisionVideoReel,
} from "@/integrations/types/integration-propvision"

export function createImageOperationOrderData(
  images: ImageType[],
  config: any,
  operation: "virtual_staging" | "image_enhancement",
  callbackParams?: any,
  orderData?: any
): IntegrationOrderCreateDataPropvisionEnhance | IntegrationOrderCreateDataPropvisionStage | undefined {
  if (!images.length || !operation || !config) {
    console.warn("no configs or operation provided", images, config, operation)
    return
  }

  const imageConfigs = images.map(image => ({
    ...config,
    image_id: image.token, // image_id is a token here (not sure why this is necessary, we could also use ID?)
    image_url: image.photo_url,
  }))

  const callbackUrl = createWebhookUrl("propvision", { ...callbackParams })

  // Create the order data
  let result = {
    operation,
    images: imageConfigs,
    webhook_url: callbackUrl,
    ...orderData,
  }

  // More specific type assertion based on operation
  if (operation === "image_enhancement") {
    return result as IntegrationOrderCreateDataPropvisionEnhance
  } else {
    return result as IntegrationOrderCreateDataPropvisionStage
  }
}

export function createVideoOperationOrderData(
  images: ImageType[],
  config: any,
  operation: "reel",
  callbackParams: any
): IntegrationOrderCreateDataPropvisionVideoReel {
  const callbackUrl = createWebhookUrl("propvision", { ...callbackParams })

  const templateSlots = config.templateSlots.reduce((acc: any, slot: any) => {
    const image = images.find(img => img.id === slot.imageId)
    acc[slot.slotId] = image?.photo_url
    return acc
  }, {})

  const { templateSlots: _, ...videoConfig } = config

  let orderData = {
    operation,
    webhook_url: callbackUrl,
    ...videoConfig,
    ...templateSlots,
  }
  return orderData as IntegrationOrderCreateDataPropvisionVideoReel
}
