<template>
  <section ref="dropzone">
    <form-dialog
      :name="
        folderParentId
          ? editableFolder && editableFolder.isPlaceholder
            ? $t('documents.form.placeholderTitle')
            : $t('documents.form.altTitle')
          : $t('documents.form.title')
      "
      :id="editableFolder.id"
      v-model:visible="folderDialogVisible"
      :saving="saving"
      @close="folderParentId = null"
      @submit="saveFolder(folderParentId)"
    >
      <form-section>
        <form-row
          :title="editableFolder.isPlaceholder ? $t('documents.form.placeholderName') : $t('documents.form.name')"
        >
          <nice-input size="small" v-model="editableFolder.name" />
        </form-row>
        <form-row
          :class="{ disabled: hasSubfolders(editableFolder.id) }"
          :title="$t('documents.form.parent')"
          v-if="canEdit"
        >
          <nice-select
            v-model="editableFolder.parentId"
            :options="editableFolder.forUpload ? folderOptionsForUpload : folderOptionsForDownload"
          />
        </form-row>
      </form-section>
      <form-section
        :class="{ disabled: editableFolder.restricted }"
        v-if="!editableFolder.isPlaceholder && !editableFolder.forUpload"
      >
        <form-row :title="$t('documents.form.email')">
          <nice-switch v-model="editableFolder.sendable" />
        </form-row>
        <form-row
          :title="$t('documents.form.lpBlock')"
          :class="{ disabled: !editableFolder.sendable }"
          :hint="$t('documents.form.lpBlockHint')"
          v-if="resourceType !== 'client'"
        >
          <nice-switch v-model="editableFolder.ignoreLpBlocked" />
        </form-row>
        <form-row :title="$t('documents.form.password')" :class="{ disabled: !editableFolder.sendable }">
          <nice-input size="small" v-model="editableFolder.password" />
        </form-row>
        <form-row :title="$t('documents.form.lp')" :class="{ disabled: !editableFolder.sendable }">
          <div class="w-full flex items-center space-x-4">
            <span class="whitespace-nowrap">{{ $t("documents.form.after") }}</span>
            <nice-input size="small" type="number" v-model="editableFolder.lpLiveDuration" />
            <span class="whitespace-nowrap">{{ $t("documents.form.hours") }}</span>
          </div>
        </form-row>
      </form-section>
      <form-section :class="{ disabled: editableFolder.restricted }" v-else-if="editableFolder.isPlaceholder">
        <form-row :title="$t('admin.media.description')">
          <nice-input size="small" :placeholder="$t('admin.media.description')" v-model="editableFolder.description" />
        </form-row>
        <form-row :title="$t('admin.media.password')">
          <nice-input size="small" v-model="editableFolder.password" />
        </form-row>
        <form-row :title="$t('admin.media.mandatory')">
          <nice-switch v-model="editableFolder.mandatory" />
        </form-row>
        <form-row :title="$t('admin.media.maxFileSize')">
          <nice-slider
            v-model="editableFolder.maxFileSize"
            :showInput="true"
            :min="1024"
            :step="1024"
            :max="20480"
            class="w-full"
          />
        </form-row>
        <form-row :title="$t('admin.media.allowedContentTypes')">
          <nice-select
            v-model="editableFolder.allowedContentTypes"
            :placeholder="$t('admin.media.allowedContentTypes')"
            multiple
            :options="contentTypes"
          />
        </form-row>
        <form-row :title="$t('admin.media.maxFiles')">
          <nice-input size="small" type="number" v-model="editableFolder.maxFiles" min="0" />
        </form-row>
        <form-row :title="$t('admin.media.disableLp')">
          <div class="w-full d-flex items-center space-x-4">
            <span class="whitespace-nowrap">{{ $t("admin.media.to") }}</span>
            <nice-input size="small" type="number" v-model="editableFolder.lpLiveDuration" />
            <span class="whitespace-nowrap">{{ $t("admin.media.hours") }}</span>
          </div>
        </form-row>
        <form-row :title="$t('admin.media.tags')">
          <nice-select
            v-model="editableItem.tags"
            multiple
            :options="tags"
            filterable
            :label-fn="tag => tag"
            :placeholder="$t('documents.tagsPh')"
          >
            <el-option v-for="item in tags" :key="item" :value="item" :label="item"></el-option>
          </nice-select>
        </form-row>
      </form-section>
      <form-section :class="{ disabled: editableFolder.restricted }" v-else></form-section>
    </form-dialog>

    <form-dialog
      :name="$t('documents.title')"
      :id="editableItem.id"
      v-model:visible="itemDialogVisible"
      :saving="saving"
      @submit="saveItem"
    >
      <form-section>
        <form-row :title="$t('documents.name')">
          <nice-input size="small" v-model="editableItem.title" />
        </form-row>
        <form-row :title="$t('documents.tags')">
          <nice-select
            v-model="editableItem.tags"
            multiple
            filterable
            :placeholder="$t('documents.tagsPh')"
            :options="tags"
            :label-fn="key => key"
          >
            <el-option v-for="item in tags" :key="item" :label="item" :value="item" />
          </nice-select>
        </form-row>
        <form-row
          v-if="resourceType !== 'client'"
          :title="$t('documents.addConnections')"
          :class="{ disabled: editableItem.isPrivate }"
        >
          <db-select collection="uniqConnections" multiple v-model="editableItem.connectionIds" />
        </form-row>

        <form-row :title="$t('documents.subConnections')" v-if="resourceType !== 'client'">
          <nice-switch v-model="editableItem.isPrivate" />
        </form-row>

        <form-row :title="$t('documents.lp')" v-if="resourceType !== 'client'">
          <nice-switch v-model="editableItem.onLandingPage" />
        </form-row>

        <form-row :title="$t('documents.exposee')" v-if="resourceType !== 'client'">
          <nice-switch v-model="editableItem.isExposee" />
        </form-row>

        <form-row :title="$t('documents.floorplan')" v-if="resourceType !== 'client'">
          <nice-switch v-model="editableItem.isFloorplan" />
        </form-row>

        <form-row :title="$t('documents.units')" v-if="resourceType == 'project'">
          <nice-switch v-model="editableItem.bequest" />
        </form-row>
      </form-section>
    </form-dialog>

    <header class="flex justify-content-between align-items-center mb-3">
      <div class="flex">
        <div>
          <h5 class="fs-16">
            {{ $customFilters.pluralize(items?.length ?? 0, $t("documents.titleSingular"), $t("documents.title")) }}
          </h5>
          <p class="text-muted fs-12" v-if="parentProjectDocumentsCount">
            +
            {{
              $customFilters.pluralize(
                parentProjectDocumentsCount,
                $t("documents.projectDoc"),
                $t("documents.projectDocs")
              )
            }}
          </p>
        </div>
        <nav class="flex items-center ml-3">
          <dropdown>
            <template v-slot:title>
              <fa-icon name="filter" class="mr-1"></fa-icon>
              <span :style="[selectedTags.length ? { color: '#0f55eb' } : null]">
                {{ selectedTagsLabel }}
              </span>
              <fa-icon name="angle-down" class="ml-1" />
            </template>
            <dropdown-section>
              <dropdown-item :title="tag" :has-toggle="true" v-for="tag in tags" :key="tag.id">
                <p class="px-3 w-100 flex justify-between" @click.stop="toggleTag(tag)">
                  <span class="fluid mr-3">{{ tag }}</span>
                  <span
                    :class="{
                      'visibility-hidden': !selectedTags.includes(tag),
                    }"
                    class="text-success"
                  >
                    <fa-icon name="check" />
                  </span>
                </p>
              </dropdown-item>
            </dropdown-section>
          </dropdown>
        </nav>
      </div>
      <aside class="flex items-center">
        <upload-button
          v-if="canEdit"
          :title="$t('documents.upload')"
          :url="baseURL"
          :onSend="onSend"
          @success="handleUpload"
          :dropzoneRef="$refs.dropzone"
          class="mr-1"
        />
        <dropdown>
          <template v-slot:title>
            <fa-icon name="ellipsis-h" />
          </template>
          <dropdown-section>
            <dropdown-item :disabled="!canEdit" :title="$t('documents.create')" @click="openFolderDialog" />
            <dropdown-item
              v-if="$db.shopData.activeFeatures.includes('placeholder_upload')"
              :disabled="!canEdit"
              :title="$t('documents.createUpload')"
              @click="() => openFolderDialog({}, { forUpload: true })"
            />

            <dropdown-item
              :disabled="items.length <= 0"
              :title="$t('documents.downloadAll')"
              @click="downloadFiles()"
            />
          </dropdown-section>
        </dropdown>
      </aside>
    </header>
    <folder :item="tree" @update="handleUpdate" ref="folder">
      <template v-slot:item="{ item }">
        <media-item :title="item.title" :url="item.url" class="mt-2" compact>
          <template v-slot:icon>
            <div class="flex items-center">
              <file-icon class="fa-fw transform scale-110" :contentType="item.contentType" />
            </div>
          </template>
          <template v-slot:title>
            <span class="flex items-center justify-between">
              <span>
                <span>{{ item.title }}</span>
                <nice-label
                  size="small"
                  class="ml-1"
                  v-show="item.isExposee"
                  v-if="resourceType !== 'client'"
                  :label="$t('documents.labels.exposee')"
                ></nice-label>
                <nice-label
                  size="small"
                  class="ml-1"
                  v-show="item.isFloorplan"
                  v-if="resourceType !== 'client'"
                  :label="$t('documents.labels.floorplan')"
                ></nice-label>
                <nice-label size="small" class="ml-1" v-for="tag in item.tags" :label="tag" :key="tag"></nice-label>
              </span>
              <small class="flex align-center font-normal text-gray-600 ml-2 text-rightx">
                <nice-label
                  :label="$t('documents.labels.connections')"
                  size="small"
                  class="ml-3"
                  :class="{ 'line-through': item.isPrivate }"
                  :color="item.isPrivate ? '#AAAAAA' : '#00dd00'"
                  clickable
                  @click.stop="updateDoc(item, 'isPrivate')"
                  v-if="resourceType !== 'client'"
                />
                <nice-label
                  :label="$t('documents.labels.lp')"
                  size="small"
                  class="ml-3"
                  :class="{ 'line-through': !item.onLandingPage }"
                  :color="!item.onLandingPage ? '#AAAAAA' : '#00dd00'"
                  clickable
                  @click.stop="updateDoc(item, 'onLandingPage')"
                  v-if="resourceType !== 'client'"
                />
                <nice-label
                  :label="$t('documents.labels.units')"
                  size="small"
                  class="ml-3"
                  :class="{ 'line-through': !item.bequest }"
                  :color="!item.bequest ? '#AAAAAA' : '#00dd00'"
                  clickable
                  @click.stop="updateDoc(item, 'bequest')"
                  v-if="resourceType === 'project'"
                />
                <span style="min-width: 54px" class="ml-3">{{ $customFilters.humanFileSize(item.fileSize) }}</span>
                <span :title="$customFilters.datetime(item.createdAt)" style="min-width: 190px" class="ml-2">
                  hochgeladen am
                  {{ $customFilters.datetime(item.createdAt) }}
                  <span v-if="item.uploadedByClient">von {{ item.uploadedByClient.name }}</span>
                </span>
              </small>
            </span>
          </template>
          <template v-slot:actions>
            <nice-tooltip :content="$t('documents.copy')">
              <hover-action @click="copy(item)" icon="brackets-curly" />
            </nice-tooltip>
            <nice-tooltip v-if="!clientId && item.contentType.match(/image\//i)" :content="$t('documents.copyAsImage')">
              <hover-action @click="copyDocumentToImages(item)" icon="file-image" />
            </nice-tooltip>
            <hover-action v-if="canEdit" @click="openItemDialog(item)" icon="pencil" />
            <hover-action v-if="canEdit" sensitive @click="deleteItem(item)" icon="trash-alt" class="text-danger" />
          </template>
        </media-item>
      </template>

      <template v-slot:folder="{ item, level, toggleOpen, open }">
        <media-item v-if="level > 0" :title="item.title" class="mt-2" @click="toggleOpen" compact>
          <template v-slot:icon>
            <fa-icon
              :class="{
                'text-yellow-500': !item.forUpload && !item.isPlaceholder,
                'text-blue-500': item.forUpload,
              }"
              class="fa-fw transform scale-110"
              :name="item.isPlaceholder ? 'file-upload' : open ? 'folder-open' : 'folder'"
            ></fa-icon>
          </template>
          <template v-slot:title>
            <div class="w-full d-flex justify-between align-items-center">
              <div class="d-flex">
                <span>{{ item.name }} ({{ (item.items && totalDocs(item.items)) || 0 }})</span>
              </div>
              <div v-if="item.isPlaceholder">
                <nice-label
                  :label="contentTypeMap[contentType]"
                  :key="contentType"
                  v-for="contentType in item.allowedContentTypes"
                  size="small"
                  class="ml-1"
                />
                <small class="text-gray-600 ml-2 mr-2">Max. {{ item.maxFileSize }} Kb</small>
              </div>
            </div>
          </template>
          <template v-slot:actions>
            <nice-tooltip v-if="level == 1 && canEdit && !item.forUpload" :content="$t('documents.createSubfolder')">
              <hover-action icon="folder-plus" @click="openFolderDialog({}, { parentId: item.id })" />
            </nice-tooltip>
            <nice-tooltip v-if="level == 1 && canEdit && item.forUpload" :content="$t('documents.createPlaceholder')">
              <hover-action
                icon="file-plus"
                @click="
                  openFolderDialog(
                    {},
                    {
                      parentId: item.id,
                      isPlaceholder: true,
                      forUpload: true,
                      maxFiles: 1,
                      maxFileSize: 1024,
                      mandatory: true,
                    }
                  )
                "
              />
            </nice-tooltip>
            <nice-tooltip v-if="canEdit && item.items.find(i => !i.folder)" :content="$t('documents.downloadAll')">
              <hover-action icon="folder-download" @click="downloadFiles(item)" />
            </nice-tooltip>
            <upload-button
              v-if="canEdit && !item.forUpload"
              :url="baseURL"
              :onSend="formData => onFolderSend(item, formData)"
              @success="handleUpload"
              v-slot="{ open, uploading }"
            >
              <hover-action @click="open" icon="cloud-upload" :loading="uploading" :disabled="uploading" />
            </upload-button>
            <nice-tooltip :content="item.sendable || item.forUpload ? $t('documents.copy') : $t('documents.sendable')">
              <hover-action
                :disabled="!item.sendable && !item.forUpload"
                @click="() => $refs[`clipboardCopy-${item.id}`].execute()"
                icon="brackets-curly"
              />
            </nice-tooltip>
            <hover-action v-if="!item.global && canEdit" @click="openFolderDialog(item)" icon="pencil" />
            <hover-action
              v-if="!item.global && canEdit"
              @click="deleteItem(item)"
              icon="trash-alt"
              class="text-danger"
            />
            <clipboard-copy
              :ref="`clipboardCopy-${item.id}`"
              :value="
                item.forUpload
                  ? `{{ dokument_upload_${item.id}_button | linktext: '${item.name}' }}`
                  : `{{ dokument_ordner_${item.id}_button | linktext: '${item.name}' }}`
              "
            />
          </template>
        </media-item>
        <div v-else></div>
      </template>
    </folder>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { Folder } from "@/interfaces"
import FolderComp from "./Folder.vue"
import { arrayToTree, removeEmptyItems } from "./folderHelper"
import UploadButton from "./UploadButton.vue"
import MediaItem from "@/components/media/Item.vue"
import { snakeCase } from "@/utils/with-case"
import GradientFlashText from "../integrations/propvision/GradientFlashText.vue"

interface Tree {
  items: Item[]
}

interface Item extends Record<string, any> {
  name: string
  id: number
  folder?: boolean
  items?: Item[]
  folderId?: number
  parentId?: number
  isPlaceholder?: boolean
  forUpload?: boolean
  maxFileSize?: number
  description?: string
  documentTags?: string[]
  allowedContentTypes?: string[]
  multiple?: boolean
}

export default defineComponent({
  props: {
    resourceType: { type: String },
    property: { type: Object },
    project: { type: Object },
    clientId: { type: Number },
    propertyId: { type: Number },
    projectId: { type: Number },
    parentProjectDocumentsCount: { type: Number },
    tags: { type: Array as () => any[], default: [] },
    initialItems: { type: Array as () => Item[], default: [] },
    initialFolders: { type: Array as () => Item[], default: [] },
    brokerIds: {
      type: Array,
    },
    readonly: {
      type: Boolean,
    },
    client: {
      type: Object,
    },
    generatingVideosCount: {
      type: Number,
      default: 0,
    },
  },
  components: { Folder: FolderComp, UploadButton, MediaItem, GradientFlashText },
  data() {
    return {
      selected: [],
      items: this.initialItems,
      folders: this.initialFolders.map(o => ({ ...o, folder: true })),
      editableItem: {} as Item,
      editableFolder: {} as Folder,
      itemDialogVisible: false,
      folderDialogVisible: false,
      saving: false,
      selectedTags: [] as any[],
      folderParentId: null as any,
    }
  },
  watch: {
    initialItems(newVal) {
      this.items = newVal
    },
  },
  methods: {
    copy(item) {
      this.$util.copy(`{{ dokument_link_${item.id} | linktext: "${item.title}" }}`)
      App.flashy(this.$t("documents.copySuccess"))
    },
    async handleUpload(item: Item) {
      const { document } = await this.$graphql(`{
        document(id: ${item.id}) {
          id, url, title, tags, contentType, fileSize, createdAt,
          isPrivate, onLandingPage, isExposee, isFloorplan,
          folderId uploadedByClient { name }, bequest
        }
      }`)

      this.items.push(document)
    },
    openItemDialog(item = {}) {
      this.editableItem = JSON.parse(JSON.stringify(item))
      this.itemDialogVisible = true
    },
    openFolderDialog(item = {}, options = {}) {
      this.editableFolder = Object.assign(JSON.parse(JSON.stringify(item)), options)
      this.folderDialogVisible = true
      if (this.editableFolder.parentId) this.folderParentId = this.editableFolder.parentId
    },
    handleUpdate({ itemId, parentId }: { itemId: number; parentId: number }) {
      // there is no re-ordering of items in the folder!!!
      // const match = this.items.find(item => item.id === itemId)
      if (!itemId && !parentId) return
      this.items = this.items.map(item => (item.id == itemId ? { ...item, folderId: parentId } : item))
      this.$axios.put(`${this.baseURL}/${itemId}`, { folder_id: parentId || null }).catch(this.$axios.handleError)
    },
    onSend(formData) {
      if (this.clientId) {
        formData.append("document[client_id]", this.clientId)
      }
      if (this.propertyId) {
        formData.append("document[property_id]", this.propertyId)
      }
      if (this.projectId) {
        formData.append("document[project_id]", this.projectId)
      }
    },
    onFolderSend(folder, formData) {
      this.onSend(formData)
      formData.append("document[folder_id]", folder.id)
    },
    toggleTag(tag) {
      if (this.selectedTags.includes(tag)) {
        this.selectedTags = this.selectedTags.filter(item => item !== tag)
      } else {
        this.selectedTags.push(tag)
      }
    },
    updateDoc(doc: Item, field: string) {
      this.items = this.items.map(item => (item.id == doc.id ? { ...item, [field]: !doc[field] } : item))
      this.$api.update("Document", doc.id, { [field]: !doc[field] })
    },
    deleteItem(item) {
      if (item.folder) {
        if (item.items && item.items.length) return App.alert(this.$t("documents.alert"))
        this.$warn(
          {
            title: this.$t("documents.delete.title", { var: this.$t("documents.delete.varFolder") }),
            desc: this.$t("documents.delete.warnFolder"),
            redButton: true,
          },
          () => {
            this.$api
              .destroy("Folder", item.id)
              .then(() => {
                this.folders = this.folders.filter(f => f.id != item.id)
                App.flashy(this.$t("documents.delete.success", { var: this.$t("documents.delete.varFolder") }))
              })
              .catch(this.$axios.handleError)
          }
        )
      } else {
        this.$warn(
          {
            title: this.$t("documents.delete.title", { var: this.$t("documents.delete.varDoc") }),
            desc: this.$t("documents.delete.warnDoc"),
            redButton: true,
          },
          () => {
            this.$axios
              .delete(`${this.baseURL}/${item.id}`)
              .then(() => {
                this.items = this.items.filter(o => o.id !== item.id)
                App.flashy(this.$t("documents.delete.success", { var: this.$t("documents.delete.varDoc") }))
              })
              .catch(this.$axios.handleError)
          }
        )
      }
    },
    saveItem() {
      this.saving = true

      this.$axios
        .request({
          method: "PUT",
          url: `${this.baseURL}/${this.editableItem.id}`,
          data: { document: _.mapKeys(this.editableItem, (_value, key) => snakeCase(key.toString())) },
        })
        .then(_result => {
          this.saving = false
          this.items = this.items.map(item => (item.id === this.editableItem.id ? this.editableItem : item))
          this.itemDialogVisible = false
        })
        .catch(err => {
          this.saving = false
          this.$axios.handleError(err)
        })
    },
    saveFolder(parentId) {
      // this.saving = true
      const payload = _.pick(this.editableFolder, [
        "name",
        "sendable",
        "lpLiveDuration",
        "password",
        "mandatory",
        "ignoreLpBlocked",
        "parentId",
        "forUpload",
        "isPlaceholder",
        "description",
        "allowedContentTypes",
        "maxFileSize",
        "maxFiles",
        "documentTags",
        "multiple",
      ])

      if (this.editableFolder.id) {
        this.folders = this.folders.map(folder => (folder.id === this.editableFolder.id ? this.editableFolder : folder))
        this.$api
          .update("Folder", this.editableFolder.id, { ...payload, parentId: this.editableFolder.parentId || null })
          .then(_ => {
            this.folderDialogVisible = false
          })
          .catch(this.$axios.handleError)
      } else if (parentId) {
        const folder = this.folders.find(f => f.id == parentId)
        if (folder) {
          this.$api
            .create("Folder", {
              ...payload,
              parentId: parentId,
              propertyId: this.propertyId,
              projectId: this.projectId,
              clientId: this.clientId,
            })
            .then(({ id }) => {
              if (Array.isArray(folder.items))
                folder.items.push({
                  ...this.editableFolder,
                  folder: true,
                  items: [],
                  parentId: parentId,
                  id,
                })
              this.folders.push({
                ...this.editableFolder,
                folder: true,
                items: [],
                id,
                parentId: parentId,
              })
              this.folderDialogVisible = false
            })
            .catch(this.$axios.handleError)
        }
      } else {
        this.$api
          .create("Folder", {
            ...payload,
            propertyId: this.propertyId,
            projectId: this.projectId,
            clientId: this.clientId,
          })
          .then(({ id }) => {
            this.folders.push({
              ...this.editableFolder,
              folder: true,
              items: [],
              id,
            })

            this.folderDialogVisible = false
          })
          .catch(this.$axios.handleError)
      }
    },
    totalDocs(items) {
      const subArrays = items.map(i => i.items)

      return subArrays
        .concat(items)
        .flat()
        .filter(i => i.contentType || !i.folder).length
    },
    downloadFiles(folder?: Item) {
      const url = new URL("/api/v1/documents/download", window.location.origin)

      const params = {
        property_id: this.propertyId,
        project_id: this.projectId,
        client_id: this.clientId,
        folder_id: folder?.id,
      }

      Object.entries(params).forEach(([key, value]) => {
        if (value) url.searchParams.append(key, value.toString())
      })

      window.open(url)
    },
    async copyDocumentToImages(item: Item) {
      try {
        App.flashy(this.$t("documents.copyAsImageStarted"))
        await this.$api.mutation("copyDocumentToImages", { documentId: item.id })
        App.flashy(this.$t("documents.copyAsImageSuccess"))
        location.reload()
      } catch (e) {
        this.$axios.handleError(e)
      }
    },
    hasSubfolders(id) {
      return this.folders.some(f => f.parentId == id)
    },
  },
  computed: {
    tree(): Tree {
      const folderIds = this.folders.map(f => f.id.toString())
      return {
        items: removeEmptyItems(
          arrayToTree([...this.filteredItems, ...this.folders], folderId =>
            folderId && folderIds.includes(folderId.toString()) ? folderId : undefined
          )
        ),
      }
    },
    canEdit(): boolean {
      if (this.readonly) return false

      if (this.projectId) {
        return (
          this.$db.hasRight("rightToEditUnits") ||
          (this.$db.hasRight("rightToEditSharedUnits") &&
            !!this.project &&
            (this.project.writeBrokerIds?.includes(this.$db.broker.id) ||
              this.project.writeDepartmentIds?.some(id => this.$db.broker.departmentIds.includes(id)))) ||
          (!!this.brokerIds && this.brokerIds.includes(this.$db.broker.id))
        )
      }

      if (this.propertyId) {
        return (
          this.$db.hasRight("rightToEditUnits") ||
          (this.$db.hasRight("rightToEditSharedUnits") &&
            !!this.property &&
            (this.property.brokerIds?.includes(this.$db.broker.id) ||
              this.property.departmentIds?.some(id => this.$db.broker.departmentIds.includes(id)))) ||
          (!!this.brokerIds && this.brokerIds.includes(this.$db.broker.id))
        )
      }
      if (this.clientId) {
        return (
          this.$db.hasRight("rightToEditClients") ||
          (this.$db.hasRight("rightToEditSharedClients") &&
            !!this.client &&
            (this.client.brokerId == this.$db.broker.id ||
              this.client.brokerIds?.includes(this.$db.broker.id) ||
              this.client.departmentIds?.some(id => this.$db.broker.departmentIds.includes(id)))) ||
          (!!this.brokerIds && this.brokerIds.includes(this.$db.broker.id))
        )
      }
      return true
    },
    baseURL(): string {
      return "/api/v1/documents"
    },
    sortUrl(): string {
      const sortable_type = this.projectId ? "Project" : "Property"
      const sortable_id = this.projectId || this.propertyId
      return `${this.baseURL}/sort?sortable_type=${sortable_type}&sortable_id=${sortable_id}`
    },
    filteredItems(): Item[] {
      if (this.selectedTags.length <= 0) {
        return this.items
      } else {
        return this.items.filter(item => item.tags && _.intersection(item.tags, this.selectedTags).length > 0)
      }
    },
    selectedTagsLabel(): any {
      const selected = this.tags.filter(b => this.selectedTags.includes(b))
      if (selected.length <= 0) {
        return "Tags"
      }
      const selectedItemName = selected[0]
      if (selected.length > 1) {
        return `${selectedItemName} +${selected.length - 1}`
      } else {
        return selectedItemName
      }
    },
    folderOptionsForDownload(): any {
      return this.folders
        .filter(i => !i.forUpload && !i.isPlaceholder && !i.parentId && i.id != this.editableFolder.id)
        .map(i => ({ id: i.id, name: i.name }))
    },
    folderOptionsForUpload(): any {
      return this.folders
        .filter(i => i.forUpload && !i.isPlaceholder && !i.parentId && i.id != this.editableFolder.id)
        .map(i => ({ id: i.id, name: i.name }))
    },
    contentTypes(): any {
      return [
        {
          id: "image/png",
          name: "png",
        },
        {
          id: "image/jpeg",
          name: "jpg",
        },
        {
          id: "application/pdf",
          name: "pdf",
        },
        {
          id: "application/zip",
          name: "zip",
        },
        {
          id: "application/msword",
          name: "doc",
        },
        {
          id: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          name: "docx",
        },
        {
          id: "application/vnd.ms-excel",
          name: "xls",
        },
        {
          id: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          name: "xlsx",
        },
        {
          id: "application/vnd.ms-powerpoint",
          name: "ppt",
        },
        {
          id: "application/vnd.openxmlformats-officedocument.presentationml.pesentation",
          name: "pptx",
        },
      ]
    },
    contentTypeMap(): any {
      return this.contentTypes.reduce((agg, cur) => {
        agg[cur.id] = cur.name
        return agg
      }, {})
    },
  },
})
</script>
<style lang="scss" scoped>
.hover-actions {
  display: flex;
  opacity: 0;
  > * {
    margin-left: 5px;
  }

  .folder:hover &,
  .item:hover & {
    opacity: 1;
  }
}

.item {
  padding-left: 14px;
  padding-right: 14px;
  margin-left: -14px;
  margin-right: -14px;
  &:hover {
    box-shadow: inset 0 0px 0px 1px rgba(0, 0, 0, 0.1);
  }
}

.pill {
  display: inline-block;
  padding: 3px 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: text-bottom;
  background-color: transparent;
  border-radius: 10px;
  width: 18px;
  height: 18px;
  border: 1px solid #bbbbbb;
  color: #888888;
}
</style>
