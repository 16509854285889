
import { useCore } from "@/plugins/use-core"
import {computed} from "vue";
import {Team} from "@/interfaces";

export const useTeams = () => {
  const { db } = useCore()

  const teams = computed<Team[]>(()=>db.get('teams'))

  const getTeam = (id: number | undefined): Team | undefined => {
    return id ? teams.value.find((team) => team.id === id) : undefined
  }

  return { teams, getTeam }
}
