<script setup lang="ts">
import { IntegrationId } from "@/integrations/types/integration-service"
import IntegrationProvider from "./IntegrationProvider.vue"
import McGrundrissWidgetOrder from "./McGrundrissWidgetOrder.vue"
import McGrundrissWidgetConfiguration from "./McGrundrissWidgetConfiguration.vue"

const props = defineProps<{
  integrationId: IntegrationId
}>()
</script>
<template>
  <integration-provider :integration-id="props.integrationId">
    <template v-slot:enabled="slotProps">
      <mc-grundriss-widget-order v-bind="{ ...slotProps, ...$attrs }" />
    </template>
    <template v-slot:disabled="slotProps">
      <mc-grundriss-widget-configuration v-bind="{ ...slotProps, ...$attrs }" />
    </template>
  </integration-provider>
</template>
