<script setup lang="ts">
import { IntegrationMapping } from "@/integrations/types/integration-page"
import { Integration, IntegrationMcGrundriss } from "@/integrations/types/integration-service"
import { ref } from "vue"
import IntegrationComponent from "./Integration.vue"
import McGrundrissWidgetTile from "./McGrundrissWidgetTile.vue"
import { toRefs } from "@vueuse/core"
import { useI18n } from "vue-i18n"

const props = defineProps<{
  integration: Integration
  mapping: IntegrationMapping<IntegrationMcGrundriss>
}>()

const { integration, mapping } = toRefs(props)
const configureVisible = ref(false)
</script>
<template>
  <div>
    <form-dialog
      :title="$t('integrations.integration-mcgrundriss.name')"
      :visible="configureVisible"
      @close="configureVisible = false"
      :submit-button-title="$t('integrations.actions.enable')"
      :show-footer="false"
      :handle-error="false"
    >
      <integration-component
        :integration="integration"
        @enable="configureVisible = false"
        @disable="configureVisible = false"
        show-deactivate-button
      />
    </form-dialog>
    <mc-grundriss-widget-tile
      :integration="integration"
      :mapping="mapping"
      :is-enabled="false"
      @configure="configureVisible = true"
    />
  </div>
</template>
