import { stringify } from "qs"
import config from "./config"

export const getBaseUrl = () => {
  return config.BASE_URL || `${window.location.protocol}//${window.location.host}`
}

export const getWebhookBase = () => {
  return config.WEBHOOK_URL || getBaseUrl()
}

export const createWebhookUrl = (path: string, params: any) => {
  const url = [getWebhookBase(), "api", path].join("/")
  const queryString = stringify(params, { addQueryPrefix: true })
  return `${url}${queryString}`
}
