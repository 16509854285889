import { useQuery } from "@tanstack/vue-query"

import { MaybeRef, get } from "@vueuse/core"
import { computed } from "vue"
import QueryKeys from "../query-keys"
import ServiceProvider from "../services/service-provider"
import { IntegrationId } from "../types/integration-service"
import { IntegrationOrderStatus } from "../types/integration-order-service"

export const fetchAllOrders = async (integrationId: IntegrationId, status?: IntegrationOrderStatus) => {
  const response = await ServiceProvider.orders.getOrders(integrationId, { size: 100, status })

  if (response.isSuccessful2xx) {
    return response.data ?? null
  }

  return null
}

export const useIntegrationOrdersQuery = (integrationId: MaybeRef<IntegrationId>, status?: IntegrationOrderStatus) => {
  const queryKey = computed(() => QueryKeys.orders.byIntegration(get(integrationId)))

  return useQuery({
    queryKey,
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    queryFn: () => fetchAllOrders(get(integrationId), status),
    // refetchOnMount: false,
  })
}
