import DataLakeTrackingService from "./services/data-lake-tracking-service"

export type TrackingEvent = {
  feature: string
  action: string
  metadata: Record<string, unknown>
}

// we need to track events in a global queue to send them in batches,
// otherwise each instance of the hook would send the events separately
let global_event_queue: TrackingEvent[] = []
let global_event_queue_timer: NodeJS.Timeout

export const useTracking = () => {
  const sendEvents = async () => {
    if (global_event_queue.length > 0) {
      DataLakeTrackingService.trackEvents(global_event_queue)
      global_event_queue = []
    }
  }

  // Send tracking events every 10 seconds
  if (!global_event_queue_timer) {
    global_event_queue_timer = setInterval(sendEvents, 10_000)
  }

  return {
    trackEvent: async (trackingEvent: TrackingEvent, immediate = false) => {
      if (immediate) {
        return await DataLakeTrackingService.trackEvents([trackingEvent])
      } else {
        global_event_queue.push(trackingEvent)
      }
    },
  }
}
