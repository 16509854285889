<script setup lang="ts">
import { computed } from "vue"

export interface NiceProgressProps {
  percentage: number
  showText?: boolean
  type?: string
  subtype?: "quotaUsage" | "contractDuration" | "commissionBar"
  status?: "success" | "exception" | "warning"
  excessBar?: boolean
}

const {
  subtype,
  status,
  percentage,
  showText = true,
  type = "line",
  excessBar = false,
} = defineProps<NiceProgressProps>()

const computePercentage = computed(() => {
  if (percentage < 0) {
    return 0
  }
  if (percentage > 100) {
    return 100
  }
  return percentage
})

const computedStyleClass = computed(() => {
  switch (subtype) {
    case "quotaUsage": {
      if (percentage >= 99) {
        return "red-bar"
      } else if (percentage <= 99 && percentage >= 75) {
        return "orange-bar"
      } else {
        return "green-bar"
      }
    }
    case "contractDuration": {
      if (percentage >= 31) {
        return "green-bar"
      } else if (percentage <= 15) {
        return "red-bar"
      } else {
        return "orange-bar"
      }
    }
    case "commissionBar": {
      if (excessBar) return "darkred-bar excess-progress-bar"
      if (percentage < 100 || percentage > 100.00001) {
        return "red-bar"
      } else {
        return "green-bar"
      }
    }
  }
  return ""
})
</script>

<template>
  <el-progress
    :percentage="computePercentage"
    :show-text="showText"
    :class="computedStyleClass"
    :type="type"
    :status="status"
  >
    <slot></slot>
  </el-progress>
</template>

<style>
.green-bar > .el-progress-bar > .el-progress-bar__outer > .el-progress-bar__inner {
  background-color: #5cb87a;
}
.orange-bar > .el-progress-bar > .el-progress-bar__outer > .el-progress-bar__inner {
  background-color: #e6a23c;
}
.red-bar > .el-progress-bar > .el-progress-bar__outer > .el-progress-bar__inner {
  background-color: #f56c6c;
}

.darkred-bar > .el-progress-bar > .el-progress-bar__outer > .el-progress-bar__inner {
  background-color: #7f1d1d;
}

.excess-progress-bar {
  transform: rotate(180deg);
}
.excess-progress-bar .el-progress-bar__outer {
  background: transparent;
}
</style>
