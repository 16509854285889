import { getCurrentInstance } from "vue"
import VueI18n from "vue-i18n"

type NotificationFunction = (msg: string, duration?: number) => void

export const useNotifications = (): {
  openError: NotificationFunction
  openSuccess: NotificationFunction
} => {
  const instance = getCurrentInstance()

  if (!instance) {
    throw new Error("useNotifications must be called within a composition API component")
  }

  const vm = instance.proxy!

  return {
    openError: (error: string | VueI18n.TranslateResult, duration) => vm.$alert(error, true, duration),
    openSuccess: (message: string | VueI18n.TranslateResult, duration) =>
      vm.$flashy(message, "success", true, duration),
  }
}
