<template>
  <span class="text-md font-medium gradient-flash-text">
    <slot />
  </span>
</template>

<style scoped>
.gradient-flash-text {
  position: relative;
  animation: gradient-flash 5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  background-size: 200% 200%;
}

@keyframes gradient-flash {
  0%,
  70%,
  100% {
    color: currentColor;
    background-position: 0% 50%;
  }

  20% {
    background: var(--ps-ai-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-position: 0% 50%;
  }

  50% {
    background: var(--ps-ai-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-position: 100% 50%;
    background-size: 200% 200%;
  }
}
</style>
