import { Broker } from "@/interfaces/db"
import useCore from "@/plugins/use-core"
import { computed, ref, watch } from "vue"

export type BrokerID = number

let BrokerMap = ref(new Map<BrokerID, Broker>())
let isInitialized = false

const initializeBrokerMap = () => {
  if (isInitialized) return

  const { db } = useCore()

  watch(
    () => db.shopData.brokers,
    brokers => {
      BrokerMap.value.clear()
      brokers?.forEach(broker => BrokerMap.value.set(broker.id, broker))
    },
    { immediate: true }
  )

  isInitialized = true
}

export const useBroker = () => {
  const { db } = useCore()

  initializeBrokerMap()

  const broker = computed(() => db.broker)

  const getBrokerById = (id: number) => BrokerMap.value.get(id) as Broker

  return { broker, getBrokerById }
}
