// This regex matches div elements with class="b-signature" (with single or double quotes)
// but excludes divs that also have the "email-trail" class, capturing the content inside
// The regex is multiline (m) and global (g), matching across line breaks ([\s\S]*?)
const SIGNATURE_REGEX_REPLACE =
  /<div[^>]*class=(["'])(?![^"']*email-trail[^"']*\1)[^"']*b-signature[^"']*\1[^>]*>([\s\S]*?)<\/div>/gm

const SIGNATURE_REGEX_CONTENT = /<div.*b-signature.*?>([\s\S]*?)<\/div>/

const FROALA_STYLE_ATTR_REGEX = /\sfr-original-style=".*?"/g
const FROALA_CLASS_ATTR_REGEX = /\sfr-original-class=".*?"/g

export const sanitizeFroalaHTML = html => {
  return html.replace(FROALA_STYLE_ATTR_REGEX, "").replace(FROALA_CLASS_ATTR_REGEX, "")
}

export const replaceSignature = (body, newSignature = "", appendIfNotFound = true) => {
  const foundSignature = SIGNATURE_REGEX_REPLACE.test(body)
  const newSignatureDiv = `<div class="b-signature">${newSignature}</div>`

  if (foundSignature) {
    return body.replace(SIGNATURE_REGEX_REPLACE, newSignatureDiv)
  } else if (appendIfNotFound) {
    return body + newSignatureDiv
  }
}

export const compareSignatures = (html, signature) => {
  const sanitizedHtml = sanitizeFroalaHTML(html)
  const matches = sanitizedHtml.match(SIGNATURE_REGEX_CONTENT)
  if (!matches?.length) {
    return false
  }

  const signatureContent = matches[1].replace("\n", "").trim() // remove newlines and whitespaces
  return signatureContent == signature
}
