<template>
  <nice-select
    ref="select"
    v-bind="$attrs"
    filterable
    remote
    :options="options"
    :grouped="displaySuggestions"
    :clearable="clearable"
    :multiple="multiple"
    :model-value="modelValue"
    @update:model-value="handleUpdate"
    @change="$emit('change', $event)"
    default-first-option
    :placeholder="$t('clientSelect.placeholder')"
    :remote-method="fetchClients"
    :loading="loading"
    :disabled="disabled"
    :size="size"
    :label-fn="labelFn"
    data-testid="client-select"
  >
    <template #option="{ item }">
      <client-select-option class="client-select" :client="item" :data-id="item.id" />
    </template>
  </nice-select>
</template>

<script lang="ts" setup>
import { cacheClients, cachedClientsMap } from "@/config/remote-data-cache"
import { DetailClient } from "@/interfaces"
import useCore from "@/plugins/use-core"
import { computed, nextTick, onMounted, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import ClientSelectOption from "./ClientSelectOption.vue"

export type Client = Pick<DetailClient, "id" | "name" | "email" | "phone" | "archived"> & {
  locked: boolean
  relationShip?: string
}

type Props = {
  size?: string
  includeChildren?: boolean
  disableLocked?: boolean
  disabled?: boolean
  clearable?: boolean
  fetchType?: string
  suggestContactsForPropertyIds?: number[]
}

type PropsWithoutMultiple = {
  modelValue: string
  multiple?: false
} & Props

type PropsWithMultiple = {
  modelValue: string[]
  multiple?: true
} & Props

type GroupedOption = {
  id: number
  name: string
  children: Client[]
}

type ClientSelectOption = Client | GroupedOption

const isGroupedOptions = (options: ClientSelectOption[]): options is GroupedOption[] => {
  return !!(options as GroupedOption[]).some(item => item.children.length > 0)
}

const props = withDefaults(defineProps<PropsWithMultiple | PropsWithoutMultiple>(), {
  modelValue: "",
  multiple: false,
  size: "default",
  includeChildren: true,
  disableLocked: false,
  disabled: false,
  clearable: true,
  fetchType: "",
  // can be multiple, currently used for single property in TaskForm
  suggestContactsForPropertyIds: undefined,
})

const { t } = useI18n()

const clients = ref<Client[]>([])

const loading = ref(false)
const select = ref()
const savedQuery = ref(null)
const suggestedClients = ref<Client[]>([])

const emit = defineEmits(["ready", "change", "update:modelValue"])

const clientsOptions = computed(() =>
  clients.value.map(item => ({
    ...item,
    disabled: !!(props.disableLocked && item.locked) || false,
  }))
)

const displaySuggestions = computed(() => {
  return !!props.suggestContactsForPropertyIds?.length
})

const options = computed<ClientSelectOption[]>(() => {
  return displaySuggestions.value ? groupedProperties.value : clientsOptions.value
})

const groupedProperties = computed(() => {
  const groups = [
    {
      id: 0,
      name: t("inbox.messageDetail.lnkdSuggestions.suggested"),
      children: suggestedClients.value,
    },
    {
      id: 1,
      name: t("inbox.messageDetail.lnkdSuggestions.all"),
      children: clientsOptions.value.filter(client => !suggestedClients.value.map(item => item.id).includes(client.id)),
    },
  ]

  return groups
})

const { axios, graphql } = useCore()

const fetchMethod = computed(() => {
  const type = `fetch${props.fetchType}`

  switch (type) {
    case "fetchClients":
      return fetchClients
    case "fetchCommissionSplitClients":
      return fetchCommissionSplitClients
    default:
      return defaultFetch
  }
})

const labelFn = (clientId: number) => {
  if (cachedClientsMap.get(clientId)?.name) {
    return cachedClientsMap.get(clientId)?.name
  }

  if (isGroupedOptions(options.value)) {
    return options.value
      .find(group => group.children.find(client => client.id === clientId))
      ?.children.find(client => client.id === clientId)?.name
  }

  return options.value.find(client => client.id === clientId)?.name
}

const handleUpdate = value => emit("update:modelValue", value)

const fetchSuggestedClients = async (propertyIds: number[]) => {
  const { data: relationships } = await axios.get(`/api/v1/relationships?property_ids=${propertyIds}&per=100`)

  if (!relationships.data.length) {
    suggestedClients.value = []
    return
  }

  const clientRelationShipMap = relationships.data.reduce((next, curr) => {
    next[curr.related_client_id] = curr.internal_name
    return next
  }, {})

  const { data: clients } = await axios.get(`/contacts/clients?client_ids=${Object.keys(clientRelationShipMap)}`)

  const clientRelationShips = clients.data.map(client => {
    return {
      ...client,
      relationShip: clientRelationShipMap[client.id],
    }
  })

  cacheClients(clientRelationShips)
  suggestedClients.value = clientRelationShips
}

const defaultFetch = async query => {
  const parameters = {
    q: query,
    include_children: props.includeChildren ? 1 : "",
  }

  const { data } = await axios.get(
    `/contacts/clients?${Object.keys(parameters)
      .map(key => `${key}=${parameters[key]}`)
      .join("&")}`
  )

  return data.data
}

const fetchClients = async query => {
  if (query) savedQuery.value = query
  if (!query && savedQuery.value) return // to stop refetching after first value has been selected
  if (!savedQuery.value) {
    loading.value = false
    clients.value = []
    return
  }

  if (displaySuggestions.value && props.suggestContactsForPropertyIds) {
    await fetchSuggestedClients(props.suggestContactsForPropertyIds)
  }

  loading.value = true
  const data = await fetchMethod.value(savedQuery.value)
  clients.value = data
  cacheClients(data)
  loading.value = false
}

const fetchCommissionSplitClients = query => {
  return graphql(
    `
      query CommissionSplitClients {
        commissionSplitClients(query: $query) {
          id
          name
          email
          archived
          locked
        }
      }
    `,
    { query }
  )
    .then(({ commissionSplitClients }) => commissionSplitClients)
    .catch(axios.handleError)
}

defineExpose({
  focus: () => select.value?.focus(),
})

const prefill = () => {
  if (!props.modelValue) return

  const ids = ([] as string[]).concat(props.modelValue).join(",")

  if (!ids) {
    console.warn("No client ids provided to prefill")
    return
  }

  axios
    .get(`/contacts/clients?client_ids=${ids}`)
    .then(({ data }) => {
      clients.value = data.data
      cacheClients(data.data)
      emit("ready")
    })
    .catch(error => {
      console.error(error)
    })
}

onMounted(() => {
  nextTick(() => {
    prefill()
  })
})

watch(
  () => props.suggestContactsForPropertyIds,
  newValue => {
    if (newValue) {
      fetchSuggestedClients(newValue)
    }
  }
)
</script>

<style scoped>
.client-select {
  height: initial;
  line-height: initial;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
