<template>
  <form-dialog
    :title="t(`integrations.extension-propvision.${operation}.dialogTitle`)"
    :submit-button-title="t(`integrations.extension-propvision.${operation}.dialogSubmit`)"
    @update:visible="emit('update:visible', $event ? operation : null)"
    @close="emit('close')"
    :submitFunc="handleSubmit"
    @complete="emit('complete', $event)"
    :disabled="disabled"
    :validate="validate"
    :handleError="false"
    width="800px"
    append-to-body
    footer-type="bigButton"
  >
    <VideoReelForm
      :operation="operation"
      :videoReelTemplate="videoReelTemplate"
      v-model="config"
      :images="images"
      :assigned-broker="assignedBroker"
    />
  </form-dialog>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, watch, unref } from "vue"
import VideoReelForm, { VideoReelFormProps } from "./VideoReelForm.vue"

import { ImageTypeEnhanced } from "../propvision.types"
import { get } from "@vueuse/core"
import { videoReelOptions, videoReelTemplate } from "../propvision.constants"
import { useI18n } from "vue-i18n"
import { SubmitVideoOperationParams, SubmitVideoOperationResultParams } from "./PropvisionActionVideoReel.vue"
import { Broker } from "@/interfaces/db"

const { t } = useI18n()

const defaults: VideoReelFormProps["modelValue"] = {
  templateSlots: [],
  ratio: "landscape",
  headline: "",
  subline: "",
  kpi: "",
  narration: null,
  voice: null,
  usePropertyBroker: true,
}

const config = ref<VideoReelFormProps["modelValue"]>({ ...defaults })
const disabled = ref(true)

const { images, operation, onSubmit, assignedBroker } = defineProps<{
  images: ImageTypeEnhanced[]
  operation: "reel"
  onSubmit: (params: SubmitVideoOperationParams) => Promise<SubmitVideoOperationResultParams>
  assignedBroker: Broker | null
}>()

const emit = defineEmits(["update:visible", "submit", "close", "complete"])

// check if all slots in the template are filled
const validate = () => {
  const allSlotsUsed =
    videoReelTemplate.slots.length > 0 &&
    videoReelTemplate.slots.every(templateSlot => {
      return get(config).templateSlots.some(slot => slot.slotId === templateSlot.id && slot.imageId)
    })

  const conf = get(config)

  const textsAreDefined = conf.headline && conf.subline && conf.kpi

  const hasValidRatio = conf.ratio && videoReelOptions.ratios.some(ratio => ratio.id === conf.ratio)
  const usesNarration = conf.voice && videoReelOptions.voices.some(voice => voice.id === conf.voice)
  const hasNarrationText = conf.narration && conf.narration.length > 0
  const hasValidNarration = (usesNarration && hasNarrationText) || !usesNarration
  const hasValidBrokerConf = !conf.usePropertyBroker || (conf.usePropertyBroker && assignedBroker)

  return allSlotsUsed && textsAreDefined && hasValidRatio && hasValidBrokerConf && hasValidNarration
}

watch(
  config,
  () => {
    disabled.value = !validate()
  },
  { deep: true }
)

const handleSubmit = async () => {
  const submitVal = { operation, images, config: unref(config.value) }
  return onSubmit(submitVal)
}
</script>
