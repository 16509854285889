import { useMutation, useQueryClient } from "@tanstack/vue-query"
import { MaybeRef, get } from "@vueuse/core"
import QueryKeys from "../query-keys"
import ServiceProvider from "../services/service-provider"
import { IntegrationOrder, IntegrationOrderCreateData } from "../types/integration-order-service"
import { IntegrationId } from "../types/integration-service"
import { QueryApiError } from "./query-api-error"
import { PagedResponse } from "../types/cloudios-api"

async function dispatchIntegrationOrder(integrationId: IntegrationId, order: IntegrationOrderCreateData) {
  const response = await ServiceProvider.orders.dispatchOrder(integrationId, order)
  if (response.isSuccessful2xx) {
    return response.data
  } else {
    throw new QueryApiError(response)
  }
}

export interface OrderVariables {
  order: IntegrationOrderCreateData
}

export const useIntegrationOrderByEntityDispatchMutation = (integrationId: MaybeRef<IntegrationId>) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ order }: { order: IntegrationOrderCreateData }) =>
      dispatchIntegrationOrder(get(integrationId), order),
    onSuccess: orderResponse => {
      const queryKey = QueryKeys.orders.byIntegrationByEntityId(get(integrationId), orderResponse.entityId)
      queryClient.setQueryData(queryKey, orderResponse)
    },
    onError: (_, { order }) => {
      const queryKey = QueryKeys.orders.byIntegrationByEntityId(get(integrationId), order.entityId)
      queryClient.invalidateQueries({ queryKey })
    },
  })
}

export const useIntegrationOrderDispatchMutation = (integrationId: MaybeRef<IntegrationId>) => {
  const queryClient = useQueryClient()
  const queryKey = QueryKeys.orders.byIntegration(get(integrationId))
  return useMutation({
    mutationFn: ({ order }: { order: IntegrationOrderCreateData }) =>
      dispatchIntegrationOrder(get(integrationId), order),
    onSuccess: orderResponse => {
      const orders = queryClient.getQueryData(queryKey) as PagedResponse<IntegrationOrder>
      if (orders.entries && orderResponse.id) {
        const entries = [...orders.entries?.filter(o => o.id !== orderResponse.id), orderResponse]
        const newOrders = {
          ...orders,
          entries,
        }
        queryClient.setQueryData(queryKey, newOrders)
      } else {
        queryClient.invalidateQueries({ queryKey })
      }
    },
    onError: (_, { order }) => {
      queryClient.invalidateQueries({ queryKey })
    },
  })
}
