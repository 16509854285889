<script setup lang="ts">
import { toRef } from "@vueuse/core"
import { useIntegrationAPI } from "@/integrations/use-integration-api"
import { useIntegration } from "@/integrations/use-integration"
import { IntegrationId } from "@/integrations/types/integration-service"
import { useIntegrationVisibility } from "@/integrations/use-integration-visibility"

const props = defineProps<{
  integrationId: IntegrationId
}>()
const integrationId = toRef(() => props.integrationId)
const { integration, refetch, refetchAll } = useIntegration(integrationId)
const { isWidgetHidden } = useIntegrationVisibility(integration)
const { mapping, isEnabled, isReady } = useIntegrationAPI(integration)
</script>
<template>
  <div v-if="isReady && !isWidgetHidden">
    <slot
      name="enabled"
      v-if="isEnabled"
      :integration="integration"
      :mapping="mapping"
      :refetch="refetch"
      :refetchAll="refetchAll"
    />
    <slot
      name="disabled"
      v-else
      :integration="integration"
      :mapping="mapping"
      :refetch="refetch"
      :refetchAll="refetchAll"
    />
  </div>
</template>
