import { useIntegration } from "@/integrations/use-integration"
import { useIntegrationOrders } from "@/integrations/use-integration-orders"
import useCore from "@/plugins/use-core"
import { computed } from "vue"
import { get } from "@vueuse/core"
import { PropvisionImageOperation } from "@/integrations/types/integration-propvision"
import { useI18n } from "vue-i18n"
import { useNotifications } from "@/integrations/use-notifications"
import { useTracking } from "@/integrations/use-tracking"
import { ImageTypeEnhanced } from "./propvision.types"
import { useBroker } from "@/composables/use-broker"
import useShop from "@/plugins/use-shop"

export const usePropvisionExtension = () => {
  const { db } = useCore()

  const { isEnabled, isPending: isLoadingExtensions, integration, refetch } = useIntegration("extension-propvision-ps")

  const {
    isLoading: isLoadingOrders,
    isDispatching,
    dispatchOrder,
    orders,
  } = useIntegrationOrders("extension-propvision-ps", "RUNNING")

  const { openError, openSuccess } = useNotifications()
  const { trackEvent } = useTracking()
  const { t } = useI18n()

  const { broker: currentBroker } = useBroker()
  const { shop } = useShop()

  // the extension needs to be enabled, but also a feature flag set (so we can beta-test from PS apollo flags)
  const isPropvisionEnabled = computed(() => {
    // HACK: for testing purposes, we limit the feature to a specific team ID for FALC immo on PROD
    const isFalcShop = get(shop).id == 6972
    const isInTestingTeam = get(currentBroker).teamId == 12413

    const isFeatureEnabled = get(isEnabled) && db.featureActive("propvision")

    if (isFalcShop) {
      console.log(`FALC: propvision check`, { isFeatureEnabled, isInTestingTeam })
    }

    return isFeatureEnabled && (isFalcShop ? isInTestingTeam : true)
  })

  const isLoading = computed(() => get(isLoadingExtensions) || get(isLoadingOrders))

  const submitFeedback = ({
    rating,
    comment,
    image,
  }: {
    rating: "positive" | "negative" | null
    comment: string
    image: ImageTypeEnhanced
  }) => {
    openSuccess(t("integrations.extension-propvision.generic.feedback.success"), 5000)

    trackEvent({
      feature: "integrations",
      action: "rate_propvision_generation",
      metadata: {
        product: "PROPSTACK",
        timestamp: Date.now(),
        integration: "extension-propvision-ps",
        rating,
        comment,
        image_id: image.id,
        photo_url: image.photo_url,
        original_image_id: image.ai_original_image_id,
        operation: image.ai_operation,
      },
    })
  }

  const dispatchOperation = async (data: any, operation: PropvisionImageOperation) => {
    if (get(isLoading)) {
      return
    }

    if (!data) {
      console.warn("no data or operation provided", data)
      return
    }

    const onSuccess = () => {
      if (operation === "reel") {
        openSuccess(t("integrations.extension-propvision.reel.notifications.operationStarted"), 5000)
      } else {
        openSuccess(
          t("integrations.extension-propvision.generic.notifications.operationStarted", {
            operation: t(`integrations.extension-propvision.${operation}.action`),
          }),
          5000
        )
      }
    }

    const onError = error => {
      openError(
        `${t("integrations.extension-propvision.generic.notifications.operationStartedFailed", {
          operation: t(`integrations.extension-propvision.${operation}.action`),
          error,
        })}`,
        5000
      )
    }

    return await dispatchOrder(data, { onSuccess, onError })
  }

  return {
    refetch,
    extension: integration,
    isDispatching,
    dispatchOperation,
    orders,
    isLoading,
    isPropvisionEnabled,
    submitFeedback,
  }
}
