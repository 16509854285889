import type {
  GetIntegrationResponse,
  GetIntegrationsResponse,
  IntegrationConsentRequest,
  IntegrationConsentResponse,
  IntegrationDynamicConfiguration,
  IntegrationId,
} from "../types/integration-service"
import APIMapping from "./api-mapping"
import { APIClient, ApiResponse } from "./base-client"

export class IntegrationsService extends APIClient {
  constructor() {
    super(APIMapping.integrationsService)
  }

  async updateConfiguration(
    integrationId: IntegrationId,
    configuration: IntegrationDynamicConfiguration
  ): Promise<ApiResponse<IntegrationDynamicConfiguration>> {
    return this.invokeApiWithErrorHandling(`/integrations/${integrationId}/configuration`, "PUT", configuration)
  }

  async getIntegrations(): Promise<ApiResponse<GetIntegrationsResponse>> {
    return this.invokeApiWithErrorHandling(`/integrations`, "GET")
  }

  async getIntegration(id: IntegrationId): Promise<ApiResponse<GetIntegrationResponse>> {
    return this.invokeApiWithErrorHandling(`/integrations/${id}`, "GET")
  }

  async saveConsent(
    integrationId: IntegrationId,
    body: IntegrationConsentRequest
  ): Promise<ApiResponse<IntegrationConsentResponse>> {
    return this.invokeApiWithErrorHandling(`/integrations/${integrationId}/consent`, "PUT", body)
  }

  async deleteConsent(integrationId: IntegrationId): Promise<ApiResponse<any>> {
    return this.invokeApiWithErrorHandling(`/integrations/${integrationId}/consent`, "DELETE")
  }
}

export default new IntegrationsService()
